import React from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import Layout from '../../components/en/common/Layout'
import { MetaData } from '../../components/common/meta'
import SocialIcons from '../../components/common/SocialIcons'
import Map from '../../components/common/map'
import ContactForm from '../../components/en/sections/contact-form'

import '../../styles/pages/page-contactus.scss'
import schema from '../../utils/schema/en/general'

const pageContactUs = ({ location }) => {
  const data = require('../../utils/schema/en/pages/contactUs.json')
  const images = useStaticQuery(graphql`{
    hero: file(relativePath: {eq: "hero/hero-contact.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: FULL_WIDTH)
      }
    }
  }
  `)
  const image = getImage(images.hero)
  const bgImage = convertToBgImage(image)

  return <>
      <MetaData
        data={data}
        location={location}
        type="website"
        linkAlternate={false}
      />
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema.localBusiness)}</script>
        <script type="application/ld+json">{JSON.stringify(schema.organization)}</script>
        <link rel="alternate" href="https://think-plus.gr/contact-us/" hreflang="el-gr" />
        <link rel="alternate" href="https://think-plus.gr/en/contact-us/" hreflang="en" />
      </Helmet>
      <Layout viewport={'full'}>
        <article className="page-contactus">
          <BackgroundImage
            Tag="section"
            className="contactus__hero"
            {...bgImage}
          >
            <div className="container">
              <SocialIcons type={'light'} position={'relative'} espaWhite={true} />
              <h1 className="visually-hidden">Contact Us</h1>
              <p className="contactus__hero-punchline h1">So, have you thought about it and realized that Think Plus is for you?</p>
            </div>
          </BackgroundImage>
          <section className="contactus__text">
            <div className="container medium">
              <h2 className="contactus__text-header dec-element--line-vertical">Tell us about your project to get a quick <br />estimate and a plan for making it happen.</h2>
            </div>
            <div className="container medium">
              <div className="row">
                <div className="col">
                  <h3>Quote</h3>
                  <p>We will contact you shortly and provide you with a detailed proposal that will fully cover your needs and determine the cost and the project’s timeframe.</p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h3>Technical assistance</h3>
                  <p>Provide us with the feedback you need or describe the technical issues you have and we will take immediate action to resolve them.</p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <h3>Partnership</h3>
                  <p>We will contact you to get a better picture of our possible partnership and a meeting may be held for further discussion.</p>
                </div>
              </div>
            </div>
          </section>
          <section className="contactus__info background__theme--dark">
            <div className="container medium">
              <div className="row">
                <div className="col-sm-6">
                  <p>Headquarters</p>
                  <p className="h5">
                    <a href="https://www.google.com/maps/place/Think+Plus+-+Advertising/@38.0381106,23.8048869,17z/data=!3m1!4b1!4m5!3m4!1s0x14a198de25b8ef7b:0xc223731778f7ddf7!8m2!3d38.0381106!4d23.8070756" className="link--plain" title="Find our location" target="_blank">Amarousiou Chalandriou 36B, 15125 Marousi</a>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p>Start a conversation</p>
                  <p className="h5">
                    <a href="mailto:info@think-plus.gr" className="link--plain" title="Send us a message" target="_blank">info@think-plus.gr</a>
                    &nbsp;|&nbsp;
                    <a href="tel:+302106101478" className="link--plain" title="Give us a call" target="_blank">+30 210 6101 478</a>
                   </p>
                </div>
              </div>
            </div>
          </section>
          <section className="contactus__map">
            <div className="container medium">
              <div className="row">
                <div className="col">
                  <div className="map">
                    <Map />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p className="h5">If you’re interested in building your brand or wish to use any of our other services, please fill out the form below to schedule an appointment and we will contact you shortly.</p>
                </div>
              </div>
            </div>
          </section>
          <ContactForm />
        </article>
      </Layout>
    </>;
}

export default pageContactUs
